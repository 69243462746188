<template>
  <div class="container about">
    <section class="row justify-content-center align-items-center">
      <div class="col-12 col-md-7 col-lg-4">
        <div class="head">
          <div class="text">
            <h1 v-if="showH1">
              What is
              <span>Truecoffee go</span>
            </h1>

            <h2 v-if="!showH1">
              What is
              <span>Truecoffee go</span>
            </h2>
          </div>

          <div class="supple">
            <img
              src="@/assets/images/text-circle.svg"
              class="text-circle">
            <img
              src="@/assets/images/cup-red.png"
              class="cup">
          </div>
        </div>

        <p>TRUECOFFEE GO, A COFFEE THAT IS CREATED WITH THE DNA OF OUR GENERATION, THE GENERATION THAT IS ALWAYS ON THE MOVE AND HAS AN ELEMENT OF FUN IN EVERY DAY. THE FIRST CONVENIENT CONNECTED COFFEE IN THAILAND, DIGITALISED FOOD AND BEVERAGE ONLINE ORDERING ECOSYSTEM. THESE INNOVATIONS WERE CREATED BECAUSE WE CARE ABOUT THE FUTURE THAT IS THE CORE OF OUR BELIEFS AND THAT IS WHY OUR PACKAGING ARE ECO-FRIENDLY. ENJOY YOUR FAVOURITE QUALITY DRINK CONVENIENTLY WHILE MAKING THE EARTH MORE SUSTAINABLE, SO WHAT'S THE WAIT - LET'S GO!</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showH1: false
    }
  },

  methods: {
    checkRouteName () {
      const routeName = this.$route.name

      if (routeName === 'home') {
        this.showH1 = false
      } else {
        this.showH1 = true
      }
    }
  },

  watch: {
    'route' () {
      this.checkRouteName()
    }
  },

  created () {
    this.checkRouteName()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

.about {
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;

  section {
    // height: calc(100vh - 60px);

    .head {
      display: flex;
      justify-content: space-between;

      .text {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 62px;
        width: 50%;

        h1,
        h2
        {
          font-family: 'DB Ozone X Bd';
          line-height: 0.4;
          font-size: 56px;

          span {
            font-family: 'DB Ozone X Bd';
            line-height: 0.6;
            font-size: 30px;
            color: $color-main;
          }
        }
      }

      .supple {
        width: 50%;

        .text-circle,
        .cup {
          position: relative;
        }

        .text-circle {
          width: 100%;
          animation: textCircle 10s linear infinite;
        }

        @keyframes textCircle {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        .cup {
          margin: -68% 0 0 0;
          width: 80%;
        }
      }
    }

    p {
      font-size: 16px;
    }
  }
}
</style>
